<template>
  <div class="home">
    <img
      alt="Vue logo"
      src="../assets/logo.png"
    >

    <p>
      <a
        href="/dashboard"
        target="_blank"
      >Visualizar dados</a>
    </p>
  </div>
</template>

<script setup>
</script>
